import { lazy } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { i18nInit } from '@cortex/locales/i18n/i18n';
import * as Sentry from '@sentry/react';

const Home = lazy(() => import('./views/home/home'));
const MyPlans = lazy(() => import('./views/MyPlans'));
const ChooseProduct = lazy(() => import('./views/ChooseProduct'));
const ProtectionPlanDetail = lazy(
  () => import('./views/protection-plan-details/protection-plan-details'),
);
const ClaimDetails = lazy(() => import('./views/ClaimDetails'));
const ClaimSummary = lazy(() => import('./views/ClaimSummary'));
const FileClaim = lazy(() => import('./views/FileClaim'));
const MyClaims = lazy(() => import('./views/MyClaims'));
const PolicyDetails = lazy(
  () => import('./views/safeship/views/PolicyDetails'),
);
const Profile = lazy(() => import('./views/Profile'));
const SafeShip = lazy(() => import('./views/safeship/SafeShip'));
const SIClaimDetails = lazy(
  () => import('./views/safeship/views/SiClaimDetails'),
);
const OrderLookup = lazy(() => import('./views/OrderLookup'));
const SIFileClaim = lazy(() => import('./views/safeship/views/SIFileClaim'));
const SIMyClaims = lazy(() => import('./views/safeship/views/SIMyClaims'));
const ErrorPage = lazy(() => import('./ui/components/ErrorPage'));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

i18nInit();
export function Router() {
  const navigate = useNavigate();

  return (
    <SentryRoutes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<Home />} />
        <Route path="plans" element={<MyPlans />} />
        <Route path="/plans/products" element={<ChooseProduct />} />
        <Route
          path="protection-plan/:productPurchaseId"
          element={<ProtectionPlanDetail />}
        />
        <Route path="claims" element={<MyClaims />} />
        <Route path="claims/new" element={<FileClaim />} />
        <Route path="claims/:claimId" element={<ClaimDetails />} />
        <Route path="claims/summary/:claimId" element={<ClaimSummary />} />

        <Route path="/safeship/policies" element={<SafeShip />} />
        <Route path="/safeship/details/:policyId" element={<PolicyDetails />} />
        <Route path="/safeship/claims/new" element={<SIFileClaim />} />
        <Route path="/safeship/claims" element={<SIMyClaims />} />
        <Route path="/safeship/claims/:claimId" element={<SIClaimDetails />} />

        <Route path="order-lookup/find" element={<OrderLookup />} />
        <Route path="order-lookup/find/:merchant" element={<OrderLookup />} />
        <Route path="profile" element={<Profile />} />
        <Route
          path="not-found"
          element={
            <ErrorPage
              callToAction={() => navigate('/')}
              ctaLabel="Back to Home"
            />
          }
        />
        <Route path="*" element={<Navigate to="not-found" />} />
      </Route>
    </SentryRoutes>
  );
}
