import { forwardRef, LegacyRef } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

interface SelectDateProps {
  error?: string;
  valid?: boolean;
  className?: string;
  label?: string;
  labelHtmlFor?: string;
  name?: string;
  infoText?: string;
  infoTextVisible?: boolean;
  onChange: (e: any) => void;
  selected?: Date;
  disabled?: boolean;
  placeholder?: string;
  infoTextClassName?: string;
  errorMessageClassName?: string;
  containerClassName?: string;
  maxDate?: Date;
  minDate?: Date;
  value?: string;
}

export const SimpleSelectDate = (
  {
    error,
    label,
    className,
    infoText,
    infoTextVisible,
    disabled,
    placeholder,
    infoTextClassName = '',
    errorMessageClassName = '',
    containerClassName = '',
    minDate,
    maxDate,
    value,
    ...props
  }: SelectDateProps,
  ref: LegacyRef<DatePicker<never, undefined>> | undefined,
) => {
  const getBorderColor = (defaultState = 'reguard-slate') =>
    (error && 'reguard-error') ||
    (value && 'reguard-wintergreen') ||
    defaultState;

  return (
    <div className={containerClassName}>
      <label
        className="flex justify-center select-none flex-col-reverse"
        htmlFor={props.name}
      >
        <DatePicker
          placeholderText={placeholder}
          maxDate={maxDate}
          minDate={minDate}
          className={`outline-none font-normal h-12 text-reguard-indigo mb-1 mt-2 pl-2 border-2 rounded w-full flex-auto
        hover:bg-reguard-pearl-tint focus-within:border-reguard-wintergreen bg-transparent disabled:bg-reguard-inactive1 disabled:text-reguard-inactive2

        ${
          (error && 'placeholder:text-reguard-error') ||
          'placeholder:border-reguard-wintergreen'
        }

        hover:border-reguard-violet focus:border-reguard-violet ${className}

        border-${getBorderColor()}`}
          disabled={disabled}
          {...props}
          ref={ref}
          autoComplete="off"
        />

        <span className={`b2 ${error ? 'text-reguard-error' : ''}`}>
          {label}
        </span>
      </label>
      {infoTextVisible && (
        <div
          className={`text-sm text-reguard-violet semibold mt-2 sm:absolute ${
            error ? 'sm:top-[6rem]' : ''
          } ${infoTextClassName}`}
        >
          {infoText}
        </div>
      )}
      {error && (
        <div
          className={`c2 semibold text-reguard-error ${errorMessageClassName}`}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export const SimpleSelectDateForwardRef = forwardRef(SimpleSelectDate);
